













































































































import { snackController } from '@/components/snack-bar/snack-bar-controller'
import { promiseHelper } from '@/helper/promise-helper'
import { Component, Inject, Vue } from 'vue-property-decorator'
import { StakingViewModel } from '../viewmodels/staking-viewmodel'

@Component
export default class UnstakingDialog extends Vue {
  @Inject({}) vm!: StakingViewModel
  dialog = false
  resolve?: (val: any) => void

  accept = false

  async open() {
    this.dialog = true
    this.accept = false
    await promiseHelper.delay(100)
    return new Promise((resolve) => (this.resolve = resolve))
  }

  async cancel() {
    this.resolve && this.resolve(null)
    this.dialog = false
    this.accept = false
    await promiseHelper.delay(100)
  }

  async unstake() {
    try {
      this.vm.unstakeLoadingChange(true)
      await this.vm.unstake()
      this.resolve && this.resolve('success')
      this.dialog = false
      this.accept = false
      await promiseHelper.delay(100)
      snackController.success('Unstake successfully')
    } catch (e: any) {
      snackController.error(e.message || e.msg || e)
    } finally {
      this.vm.unstakeLoadingChange(false)
    }
  }

  tierText = '1'

  get tier() {
    let day = ''
    if (this.tierText === '1') {
      day = '10'
    } else if (this.tierText === '2') {
      day = '20'
    } else if (this.tierText === '3') {
      day = '30'
    } else if (this.tierText === '4') {
      day = '60'
    } else if (this.tierText === '5') {
      day = '90'
    }
    return { tier: 'TIER ' + this.tierText, day: day + ' DAYs' }
  }

  get width() {
    switch (this.$vuetify.breakpoint.name) {
      case 'xs':
      case 'sm':
        return 320
      case 'md':
        return 431
      case 'lg':
      case 'xl':
        return 593
    }
  }
}































import { Component, Provide, Ref, Vue } from 'vue-property-decorator'
import TopTearTable from '../components/top-tear-table.vue'
import StakeInfo from '../components/stake-info.vue'
import StakeBox from '../components/stake-box.vue'
import StakingDialog from '../dialogs/staking-dialog.vue'
import UnStakingdDialog from '../dialogs/unstaking-dialog.vue'
import { StakingViewModel } from '../viewmodels/staking-viewmodel'

@Component({
  components: {
    TopTearTable,
    StakeInfo,
    StakeBox,
    StakingDialog,
    UnStakingdDialog,
  },
})
export default class App extends Vue {
  @Provide() vm = new StakingViewModel()

  @Ref('staking-dialog') stakingDialog!: any
  @Ref('unstaking-dialog') unstakingDialog!: any
  @Ref('confirm-transaction-dialog') confirmTransactionDialog!: any

  async showDialog(stake: boolean) {
    if (stake) {
      if (!this.vm.verifyStakeAmountInput()) return
      await this.stakingDialog.open()
    } else {
      if (!this.vm.verifyUnstakeAmountInput()) return
      await this.unstakingDialog.open()
    }
  }

  destroyed() {
    this.vm.destroy()
  }
}

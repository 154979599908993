





































import { Component, Inject, Vue } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'
import { walletStore } from '@/stores/wallet-store'
import { StakingViewModel } from '../viewmodels/staking-viewmodel'

@Observer
@Component({
  components: {},
})
export default class StakeInfo extends Vue {
  wallet = walletStore
  @Inject({}) vm!: StakingViewModel
}

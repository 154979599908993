

























































































import { snackController } from '@/components/snack-bar/snack-bar-controller'
import { promiseHelper } from '@/helper/promise-helper'
import { Observer } from 'mobx-vue'
import { Component, Inject, Vue } from 'vue-property-decorator'
import { StakingViewModel } from '../viewmodels/staking-viewmodel'

@Observer
@Component
export default class StakingDialog extends Vue {
  @Inject({}) vm!: StakingViewModel
  dialog = false
  resolve?: (val: any) => void

  async open() {
    this.dialog = true
    this.accept = false
    await promiseHelper.delay(100)
    return new Promise((resolve) => (this.resolve = resolve))
  }

  async cancel() {
    this.resolve && this.resolve(null)
    this.dialog = false
    this.accept = false
    await promiseHelper.delay(100)
  }

  async stake() {
    try {
      this.vm.stakeLoadingChange(true)
      await this.vm.onStake()
      this.resolve && this.resolve('success')
      this.accept = false
      this.dialog = false
      await promiseHelper.delay(100)
      snackController.success('Stake successfully')
    } catch (e: any) {
      snackController.error(e.message || e.msg || e)
    } finally {
      this.vm.stakeLoadingChange(false)
    }
  }

  accept = false

  get width() {
    switch (this.$vuetify.breakpoint.name) {
      case 'xs':
      case 'sm':
        return 320
      case 'md':
        return 431
      case 'lg':
      case 'xl':
        return 593
    }
  }
}







































































































import { Component, Inject, Vue } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'
import { StakingViewModel } from '../viewmodels/staking-viewmodel'
import { walletStore } from '@/stores/wallet-store'
@Observer
@Component({
  components: {
    BoxInput: () => import('./box-input.vue'),
  },
})
export default class StakeBox extends Vue {
  @Inject() vm!: StakingViewModel
  walletStore = walletStore
  focus = false
}

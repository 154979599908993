

























































































import { Observer } from 'mobx-vue'
import { Component, Vue, Inject } from 'vue-property-decorator'
import { StakingViewModel } from '../viewmodels/staking-viewmodel'

@Observer
@Component({
  components: {},
})
export default class AppliedProjectTable extends Vue {
  @Inject() vm!: StakingViewModel
  page = 1
  checkbox = true
  headers = [
    { text: 'Project Name', value: 'name', sortable: false, width: '40%' },
    { text: 'Allocation date (UTC)', value: 'date', sortable: false, align: 'center', width: '20%' },
    { text: 'Contribution', value: 'contribution', sortable: false, align: 'center', width: '20%' },
    { text: 'Token', value: 'token', sortable: false, align: 'center', width: '20%' },
  ]
}

import { StakingHandler } from '@/blockchainHandler/stakingHandler'
import { action, computed, IReactionDisposer, observable, reaction, runInAction, when } from 'mobx'
import { asyncAction } from 'mobx-utils'
import { walletStore } from '@/stores/wallet-store'
import { Subject, Subscription, timer } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import { tierHelper, Zero } from '@/constants'
import moment, { duration } from 'moment'
import { ceil, round } from 'lodash-es'
import { bnHelper } from '@/helper/bignumber-helper'
import { FixedNumber } from '@ethersproject/bignumber'
import { checkOptInStatus } from '@/blockchainHandler/utils'
import { apiService } from '@/services/api-services'
import { snackController } from '@/components/snack-bar/snack-bar-controller'

export class StakingViewModel {
  @observable stakingDialog = false

  @observable stakedTokenAmount = Zero
  @observable userLockDuration = duration(0)
  @observable userTierIndex = 0
  @observable lastTimeStake: moment.Moment | null = null
  @observable currentTime = moment()
  @observable userBalanceASA = Zero
  @observable fetchingData = false
  @observable isOptIn = false
  @observable optInLoading = false
  @observable stakeLoading = false
  @observable unstakeLoading = false
  @observable loaded = false
  @observable firstFetched = false
  @observable stakeAmountInput = ''
  @observable stakeAmountInputError = ''
  @observable unstakeAmountInput = ''
  @observable unstakeAmountInputError = ''
  @observable isStakeOn = true
  @observable top10Staker = []

  stakingHandler: StakingHandler | undefined = undefined

  private _fetchUserInfoSubscription?: Subscription
  private _unsubscribe = new Subject()
  private _disposers: IReactionDisposer[] = []

  destroy() {
    this._disposers.forEach((d) => d())
  }

  constructor() {
    this.loadData()
  }

  @asyncAction *loadData() {
    this.fetchStakingStatistics()
    const stakeHandler = new StakingHandler()
    this.stakingHandler = stakeHandler
    if (!this.stakingHandler) return
    yield this.stakingHandler.loadData()

    this._disposers = [
      reaction(
        () => walletStore.account,
        async (x) => {
          this._fetchUserInfoSubscription?.unsubscribe()
          this.clearUser()
          if (x) {
            this.firstFetched = false
            try {
              this.isOptIn = await checkOptInStatus(walletStore.account, stakeHandler.appID)
            } catch (e) {
              console.log('loadData-checkOptInStatus failed')
            }
            await this.fetchUserInfo()
            this.firstFetched = true
            this._fetchUserInfoSubscription = timer(60000)
              .pipe(takeUntil(this._unsubscribe))
              .subscribe(async () => {
                await this.fetchUserInfo()
              })
          }
        },
        {
          fireImmediately: true,
        }
      ),
    ]

    this.loaded = true
  }

  @asyncAction *fetchStakingStatistics() {
    try {
      const top10Staker = yield apiService.stakingStatistics.find({ _sort: 'formatedStakedAmount:DESC', _limit: 10 })
      const promises = [] as any
      top10Staker.map((item) => {
        promises.push(this.mappingStakerInfo(item))
      })
      this.top10Staker = yield Promise.all(promises)
    } catch (e) {
      snackController.commonError(e)
    }
  }

  // note: userName not username
  @asyncAction *mappingStakerInfo(item) {
    const user = item.walletAddress
      ? yield apiService.users.find({ username: item.walletAddress }, { _limit: 1 })
      : undefined
    if (user[0]) {
      return { ...item, userName: user[0].userName || '', lastStakeTime: item.lastStakeTime * 1000 }
    } else return { item, lastStakeTime: item.lastStakeTime * 1000 }
  }

  @action clearUser() {
    this.userTierIndex = 0
    this.lastTimeStake = null
    this.userLockDuration = duration(0)
    this.userBalanceASA = Zero
    this.stakedTokenAmount = Zero
    this.isOptIn = false
    this.stakeAmountInput = ''
    this.stakeAmountInputError = ''
    this.unstakeAmountInput = ''
    this.unstakeAmountInputError = ''
  }

  @asyncAction *fetchUserInfo() {
    if (!this.stakingHandler) return
    try {
      this.currentTime = moment()
      const { amount, tier, stakeTime } = yield this.stakingHandler.getUserInfo(walletStore.account)
      this.userTierIndex = tier
      this.lastTimeStake = bnHelper.toMoment(stakeTime)
      this.userLockDuration = duration(tierHelper.getTierList()[tier]?.period || 0, 'day')
      this.stakedTokenAmount = amount
      this.userBalanceASA = yield this.stakingHandler.getUserASABalance(walletStore.account)
    } catch (e) {
      console.log('fetchUserInfo failed')
    }
  }

  @action switchStakeSelector(value) {
    this.isStakeOn = value
  }

  @action.bound onChangeUnstakeInput(val: any) {
    this.unstakeAmountInput = val
  }
  @action.bound onChangeStakeInput(val: any) {
    this.stakeAmountInput = val
  }

  @action.bound maxAmountInput() {
    this.resetErrorMessage()
    if (this.isStakeOn) this.stakeAmountInput = this.userBalanceASA.toString()
    else this.unstakeAmountInput = this.stakedTokenAmount.toString()
  }

  @action verifyStakeAmountInput() {
    this.stakeAmountInputError = ''
    if (bnHelper.gt(this.validStakeAmountInput, this.userBalanceASA)) {
      this.stakeAmountInputError = 'Balance is insufficient'
      return false
    }

    if (!bnHelper.gt(this.validStakeAmountInput, Zero)) {
      this.stakeAmountInputError = `Amount must be more than 0`
      return false
    }

    if (bnHelper.lt(this.totalStakeAmount, this.minTierAmount)) {
      this.stakeAmountInputError = `You have to stake at least ${this.minTierAmount} LCH`
      return false
    }

    return true
  }

  @action verifyUnstakeAmountInput() {
    this.unstakeAmountInputError = ''
    if (bnHelper.gt(this.validUnStakeAmountInput, this.stakedTokenAmount)) {
      this.unstakeAmountInputError = 'Unstake over amount'
      return false
    }
    if (!bnHelper.gt(this.validUnStakeAmountInput, Zero)) {
      this.unstakeAmountInputError = `Amount must be more than 0`
      return false
    }
    if (bnHelper.gt(this.stakeAmountAfterUnstake, Zero) && this.tierAfterUnstake.tierIndex === 0) {
      this.unstakeAmountInputError = `Minimum tier is ${this.minTierAmount} LCH, you must unstake all or keep at least ${this.minTierAmount} LCH`
      return false
    }
    return true
  }

  @action.bound resetErrorMessage() {
    if (this.isStakeOn && this.stakeAmountInputError) this.stakeAmountInputError = ''
    if (!this.isStakeOn && this.unstakeAmountInputError) this.unstakeAmountInputError = ''
  }

  @action unstakeLoadingChange(value) {
    this.unstakeLoading = value
  }

  @action stakeLoadingChange(value) {
    this.stakeLoading = value
  }

  @asyncAction *optIn() {
    try {
      this.optInLoading = true
      yield this.stakingHandler?.sendOptIn(walletStore.account)
      this.isOptIn = true
    } catch (e) {
      throw e
    } finally {
      this.optInLoading = false
    }
  }

  @asyncAction *onStake() {
    if (!this.stakingHandler) throw 'Staking contract is undefined'
    yield this.stakingHandler.stake(walletStore.account, this.targetTier.tierIndex, this.validStakeAmountInput)
    this.stakeAmountInput = ''
    this.stakeAmountInputError = ''
    this.fetchUserInfo()
  }

  @asyncAction *unstake() {
    if (!this.stakingHandler) throw 'Staking contract is undefined'
    yield this.stakingHandler.unstake(
      walletStore.account,
      this.tierAfterUnstake.tierIndex,
      this.validUnStakeAmountInput
    )
    this.unstakeAmountInput = ''
    this.unstakeAmountInputError = ''
    this.fetchUserInfo()
  }

  // @computed get maxTierAmount() {
  //   const tierList = tierHelper.getTierList()
  //   return tierList[tierList.length - 1].amount
  // }

  @computed get minTierAmount() {
    const tierList = tierHelper.getTierList()
    return FixedNumber.from(`${tierList[1].amount}`)
  }

  @computed get currentTier() {
    return tierHelper.getTierList()[this.userTierIndex || 0]
  }

  @computed get targetTier() {
    return tierHelper.findTierByAmount(this.totalStakeAmount.toUnsafeFloat()) || tierHelper.getTierList()[0]
  }
  @computed get tierAfterUnstake() {
    return tierHelper.findTierByAmount(this.stakeAmountAfterUnstake.toUnsafeFloat()) || tierHelper.getTierList()[0]
  }

  @computed get isNotStaker() {
    return !bnHelper.gt(this.stakedTokenAmount, Zero)
  }

  @computed get canUnstakeTime() {
    if (!this.lastTimeStake) return null
    return this.lastTimeStake.clone().add(this.userLockDuration)
  }

  @computed get isVisbleStakeInfo() {
    return bnHelper.gt(this.stakedTokenAmount, Zero)
  }

  dayToHourMinute(durationInSeconds) {
    let hours = durationInSeconds / 3600
    let mins = (durationInSeconds % 3600) / 60
    hours = Math.trunc(hours)
    mins = Math.trunc(mins)
    return `${hours}h ${mins}m`
  }

  @computed get remainStakeLockDays() {
    if (this.canUnstakeTime) {
      const diff = duration(this.canUnstakeTime.diff(this.currentTime))
      const diffDays = diff.asDays()
      if (diffDays < 0) return { time: '0h 0m', message: 'UNSTAKE NOW' }
      let message = ''
      if (diffDays >= 1) message = `${round(diffDays)} ${round(diffDays) >= 2 ? 'DAYS' : 'DAY'} LEFT`
      else {
        const hour = diff.asHours()
        if (hour >= 1) message = `${round(hour, 0)} ${round(hour, 0) >= 2 ? 'HOURS' : 'HOUR'} LEFT`
        else {
          const mins = ceil(diff.asMinutes(), 0)
          message = `${mins} ${mins >= 2 ? 'MINUTES' : 'MINUTE'} LEFT`
        }
      }
      return { time: this.dayToHourMinute(diff.asSeconds()), message }
    }
    return { time: '0h 0m', message: 'YOU HAVE NOT STAKED YET' }
  }
  @computed get canUnstake() {
    return this.canUnstakeTime && this.canUnstakeTime.isSameOrBefore(moment())
  }

  @computed get validStakeAmountInput() {
    try {
      return FixedNumber.from(this.stakeAmountInput)
    } catch (e) {
      //
    }
    return Zero
  }
  @computed get validUnStakeAmountInput() {
    try {
      return FixedNumber.from(this.unstakeAmountInput)
    } catch (e) {
      //
    }
    return Zero
  }

  @computed get totalStakeAmount() {
    return this.stakedTokenAmount.addUnsafe(this.validStakeAmountInput)
  }
  @computed get stakeAmountAfterUnstake() {
    return this.stakedTokenAmount.subUnsafe(this.validUnStakeAmountInput)
  }
}
